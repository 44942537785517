import { DefaultAPIInstance } from '@/api'

const ContragentService = {
  getUser() {
    return DefaultAPIInstance.get('main/user/')
  },
  getContragent(userId) {
    return DefaultAPIInstance.get(`client/contragent/${userId}`)
  },
  changeContragent(id) {
    return DefaultAPIInstance.put(`client/user/select_contragent/${id}`)
  },
}

export default ContragentService

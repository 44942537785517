<template>
  <ATooltip class="q-header-back" placement="bottom">
    <AButton
      class="toggle-back"
      type="link"
      @click="router.go(-1)"
    >
      <template #icon>
        <LeftOutlined class="q-back-icon" />
      </template>
    </AButton>
    <template #title>
      <span>Назад</span>
    </template>
  </ATooltip>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { LeftOutlined } from '@ant-design/icons-vue'
import { Tooltip as ATooltip } from 'ant-design-vue'

const router = useRouter()
</script>

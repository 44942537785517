import { Modal } from 'ant-design-vue'
import { createVNode } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { mapActions } from '@/utils/mapState'

function useLogout(): () => void {
  const { onLogout } = mapActions()

  return () => {
    Modal.confirm({
      title: 'Выход из приложения',
      icon: createVNode(ExclamationCircleOutlined),
      content: 'Вы действительно хотите выйти?',
      okText: 'Да',
      okType: 'danger',
      cancelText: 'Нет',
      onOk() {
        onLogout()
      },
    })
  }
}
export default useLogout

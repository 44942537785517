<!-- eslint-disable max-len -->
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6999 9.84247L11.0749 1.22194L10.4967 0.643811C10.365 0.512956 10.1869 0.439514 10.0012 0.439514C9.81553 0.439514 9.6374 0.512956 9.50567 0.643811L0.302548 9.84247C0.167573 9.97692 0.0608995 10.137 -0.0111751 10.3134C-0.0832496 10.4898 -0.119265 10.6788 -0.117095 10.8693C-0.108166 11.655 0.545851 12.2822 1.33157 12.2822H2.28023V19.5523H17.7222V12.2822H18.6909C19.0726 12.2822 19.432 12.1326 19.7021 11.8626C19.8351 11.73 19.9405 11.5724 20.0121 11.3988C20.0838 11.2252 20.1203 11.0392 20.1195 10.8514C20.1195 10.4719 19.97 10.1126 19.6999 9.84247ZM11.2512 17.9451H8.75121V13.3916H11.2512V17.9451ZM16.115 10.6751V17.9451H12.6798V12.8559C12.6798 12.3626 12.2802 11.963 11.7869 11.963H8.21549C7.72219 11.963 7.32264 12.3626 7.32264 12.8559V17.9451H3.88737V10.6751H1.74451L10.0034 2.42283L10.5191 2.93845L18.2601 10.6751H16.115Z"
    />
  </svg>
</template>

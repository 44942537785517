<!-- eslint-disable max-len -->
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M11.3619 7.9687C10.8463 7.9687 10.4468 8.36825 10.4468 8.86156C10.4468 9.35486 10.8463 9.75442 11.3619 9.75442C11.8329 9.75442 12.2325 9.35486 12.2325 8.86156C12.2325 8.36825 11.8329 7.9687 11.3619 7.9687ZM5.11194 7.9687C4.59631 7.9687 4.19676 8.36825 4.19676 8.86156C4.19676 9.35486 4.59631 9.75442 5.11194 9.75442C5.58292 9.75442 5.98247 9.35486 5.98247 8.86156C5.98247 8.36825 5.58292 7.9687 5.11194 7.9687Z"
        fill="currentColor"
      />
      <path
        d="M18.5265 6.27053C17.4938 4.84897 16.0046 3.8247 14.3077 3.36874V3.37098C13.926 2.94687 13.4952 2.55624 13.0131 2.20803C9.35908 -0.44822 4.22961 0.362048 1.5622 4.01607C-0.587352 6.98482 -0.495834 10.9781 1.69613 13.8152L1.71399 16.775C1.71399 16.8464 1.72515 16.9179 1.74747 16.9848C1.77555 17.0743 1.82098 17.1573 1.88118 17.2292C1.94138 17.301 2.01515 17.3603 2.09829 17.4037C2.18142 17.447 2.27229 17.4735 2.36568 17.4817C2.45907 17.4899 2.55316 17.4796 2.64256 17.4513L5.46845 16.5607C6.21622 16.8263 6.98854 16.9781 7.7564 17.0205L7.74524 17.0295C9.73408 18.4781 12.3412 18.9134 14.7318 18.1232L17.5689 19.0473C17.6403 19.0696 17.714 19.083 17.7899 19.083C18.185 19.083 18.5042 18.7638 18.5042 18.3687V15.3777C20.4707 12.708 20.522 9.0183 18.5265 6.27053ZM5.78095 14.9759L5.5131 14.8643L3.30327 15.5562L3.28095 13.2348L3.10238 13.0339C1.21399 10.7304 1.08899 7.41115 2.85685 4.97589C5.00863 2.02499 9.13363 1.37321 12.0756 3.50267C15.0265 5.64776 15.6805 9.76607 13.5488 12.6991C11.7609 15.1522 8.55997 16.0585 5.78095 14.9759ZM17.0533 14.5964L16.8747 14.8196L16.897 17.1411L14.7095 16.4045L14.4417 16.5161C13.1917 16.9804 11.8591 17.0183 10.6247 16.6723L10.6202 16.6701C12.3167 16.1498 13.7987 15.0932 14.8435 13.6589C16.5488 11.3085 16.8256 8.35535 15.8345 5.83749L15.8479 5.84642C16.3613 6.21473 16.8323 6.67455 17.2318 7.23035C18.8524 9.45357 18.7609 12.4804 17.0533 14.5964Z"
        fill="currentColor"
      />
      <path
        d="M8.23694 7.9687C7.72131 7.9687 7.32176 8.36825 7.32176 8.86156C7.32176 9.35486 7.72131 9.75442 8.23694 9.75442C8.70792 9.75442 9.10747 9.35486 9.10747 8.86156C9.10747 8.36825 8.70792 7.9687 8.23694 7.9687Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

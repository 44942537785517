<template>
  <li class="q-navbar-exit">
    <a class="q-navbar-exit__link" href="#" @click.prevent="exit">
      <i class="q-navbar-exit__ico">
        <LogoutOutlined />
      </i>
      <span class="q-navbar-exit__title"> Выйти </span>
    </a>
  </li>
</template>

<script setup lang="ts">
import { LogoutOutlined } from '@ant-design/icons-vue'
import useLogout from '@/сomposables/logout'

const exit = useLogout()
</script>

<style lang="sass" scoped>
@import './QNavbarExit'
</style>

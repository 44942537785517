<template>
  <header class="q-header">
    <div class="container">
      <QHeaderTop />
      <QHeaderNav v-if="isNav" />
      <QHeaderSub
        v-if="isSub"
        :style="{
          'border-top': `1px solid ${isNav ? 'transparent' : '#f0f0f0'}`,
        }"
      />
    </div>
  </header>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import QHeaderNav from '@/components/header/QHeaderNav.vue'
import QHeaderSub from '@/components/header/QHeaderSub.vue'
import QHeaderTop from '@/components/header/QHeaderTop.vue'

const route = useRoute()

const isNav = computed<boolean>(() => !!route?.meta?.tabs)
const isSub = computed<boolean>(() => !!route?.meta?.subHeader)
</script>

<style lang="sass" scoped>
@import './QHeader'
</style>

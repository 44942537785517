<!-- eslint-disable max-len -->
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 10C0 4.47768 4.47768 0 10 0C15.5223 0 20 4.47768 20 10C20 15.5223 15.5223 20 10 20C4.47768 20 0 15.5223 0 10ZM1.69643 10C1.69643 14.5848 5.41518 18.3036 10 18.3036C14.5848 18.3036 18.3036 14.5848 18.3036 10C18.3036 5.41518 14.5848 1.69643 10 1.69643C5.41518 1.69643 1.69643 5.41518 1.69643 10ZM7.27458 13.5014V15H9.47062V13.5014H11.7103V12.1429H9.47062V11.0504H10.4159C11.4243 11.0504 12.2193 10.9057 12.801 10.6162C13.3925 10.3268 13.8142 9.94398 14.0663 9.46779C14.3184 8.9916 14.4444 8.48273 14.4444 7.94118C14.4444 7.02614 14.1342 6.30719 13.5137 5.78431C12.9029 5.26144 11.9284 5 10.5905 5H7.27458V9.31373H6.11111V11.0504H7.27458V12.1429H6.11111V13.5014H7.27458ZM11.6667 9.0056C11.3079 9.21102 10.8183 9.31373 10.1978 9.31373H9.47062V6.73669H10.4741C11.6376 6.73669 12.2193 7.16153 12.2193 8.0112C12.2193 8.46872 12.0351 8.80019 11.6667 9.0056Z"
    />
  </svg>
</template>

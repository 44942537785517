import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import residential from '@/router/residential'

const main: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/main/QLogin.vue'),
    meta: {
      guest: true,
      layout: 'layout-guest',
    },
  },
  {
    path: '/registration',
    name: 'Registration',
    component: () => import('@/pages/main/QRegistration.vue'),
    meta: {
      guest: true,
      layout: 'layout-guest',
    },
  },
  {
    path: '/password-recovery',
    name: 'RecoveryPassword',
    component: () => import('@/pages/main/QRecoveryPassword.vue'),
    meta: {
      guest: true,
      layout: 'layout-guest',
    },
  },
  {
    path: '/service-unavailable',
    name: 'ServiceUnavailable',
    component: () => import('@/pages/main/QServiceUnavailable.vue'),
    meta: {
      guest: true,
      layout: 'layout-guest',
    },
  },
  {
    path: '/pay',
    name: 'Pay',
    component: () => import('@/pages/main/QPay.vue'),
    meta: {
      guest: true,
      layout: 'layout-guest',
    },
  },
  {
    path: '/',
    redirect: '/resident',
    meta: {
      menu: null,
      layout: 'layout-main',
    },
  },
]

const catchRoutes = [
  {
    path: '/:catchAll(.*)',
    component: () => import('@/pages/main/QPageNotFound.vue'),
    meta: {
      guest: true,
      layout: 'layout-guest',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: [...main, ...residential, ...catchRoutes],
})

export default router

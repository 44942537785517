<template>
  <div class="q-header-top">
    <span class="q-header-top__left">
      <QHeaderBack v-if="route?.meta?.backBtn" />
      <div v-else class="q-header-top__left-logo">
        <Icon>
          <template #component>
            <component :is="QIconLogo" />
          </template>
        </Icon>
      </div>
      <h2 class="q-header-top__left-title">
        {{ route?.meta?.headerTitle ?? route?.query?.name ?? route?.name }}
      </h2>
    </span>
    <div class="q-header-top__steps" id="steps" />
    <span class="q-header-top__user">
      <QUserbar />
    </span>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'
import Icon from '@ant-design/icons-vue'
import QIconLogo from '@/icons/QIconLogo.vue'
import QUserbar from '@/components/ui/userbar/QUserbar.vue'
import QHeaderBack from '@/components/header/QHeaderBack.vue'

const route = useRoute()
</script>

<style lang="sass" scoped>
@import './QHeaderTop'
</style>

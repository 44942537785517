<template>
  <div class="q-cookie-agreement-main-text">
    Привет! Мы храним и/или получаем доступ к информации на устройстве, например уникальным идентификаторам в файлах cookie для обработки персональных данных.
    Нажав «Принять и продолжить» справа, вы соглашаетесь на такую обработку.
    Вы можете ознакомится с нашей <a class="q-cookie-agreement-main-text__link" @click="emits('open')">политикой конфиденциальности</a>.
  </div>
</template>

<script setup>
const emits = defineEmits(['open'])
</script>

<style lang="sass" scoped>
@import './QCookieAgreementMainText'
</style>

import type { Module } from 'vuex'
import { message } from 'ant-design-vue'
import type { IRootStore } from '../interfaces'
import CommunicationService from '@/services/main/CommunicationService'

const notification: Module<any, IRootStore> = {
  state: {
    countNotifications: 0,
  },

  getters: {
    countNotifications: state => state.countNotifications,
  },

  actions: {
    async getNotifications({ commit }) {
      try {
        const { data } = await CommunicationService.getUnreadMessages()
        commit('setCount', data)
      }
      catch (err: any) {
        message.error(err.response.data?.details)
      }
    },
  },

  mutations: {
    setCount: (state, data) => {
      state.countNotifications = data
    },
  },
}

export default notification

import store from '@/store'

interface IConfigItem {
  development: boolean
  preprod: boolean
  production: boolean
  kzkr: boolean
}
interface IConfig {
  ehybrid: IConfigItem
  autopay: IConfigItem
  insurance: IConfigItem
}

const CONFIG: IConfig = {
  ehybrid: {
    development: true,
    preprod: false,
    production: false,
    kzkr: false,
  },
  autopay: {
    development: false,
    preprod: false,
    production: false,
    kzkr: false,
  },
  insurance: {
    development: false,
    preprod: true,
    production: true,
    kzkr: false,
  },
}

export function useShowByServer() {
  function show(block: 'ehybrid' | 'insurance' | 'autopay') {
    const propertyName: keyof IConfigItem = process.env.VUE_APP_ENVIRONMENT
    if (block === 'insurance') {
      return CONFIG[block]?.[propertyName] && store?.getters?.isMarketplaceToken
    }

    return CONFIG[block]?.[propertyName] || false
  }

  return {
    show,
  }
}

import type { Module } from 'vuex'
import type { IRootStore } from '../interfaces'

const header: Module<any, IRootStore> = {
  state: {
    sub: false,
  },

  getters: {
    sub: state => state.sub,
  },

  actions: {
  },

  mutations: {
    openSub(state) {
      state.sub = true
    },
    closeSub(state) {
      state.sub = false
    },
  },
}

export default header

<!-- eslint-disable max-len -->
<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 26 26"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.679 1.39639C23.5688 1.39639 23.4556 1.41671 23.3424 1.46313L6.61653 8.18367H1.8576C1.60224 8.18367 1.39331 8.3984 1.39331 8.66537V17.3417C1.39331 17.6087 1.60224 17.8234 1.8576 17.8234H4.80871C4.70135 18.16 4.64331 18.5169 4.64331 18.8797C4.64331 20.7919 6.20447 22.3473 8.12545 22.3473C9.73304 22.3473 11.0882 21.2562 11.4886 19.7821L23.3453 24.5468C23.4585 24.5904 23.5717 24.6136 23.6819 24.6136C24.1723 24.6136 24.6105 24.2015 24.6105 23.6502V2.35979C24.6076 1.80845 24.1723 1.39639 23.679 1.39639ZM8.12545 20.2667C7.35648 20.2667 6.7326 19.6457 6.7326 18.8797C6.7326 18.5547 6.84577 18.2442 7.05179 17.9975L9.51541 18.987C9.45737 19.7009 8.8567 20.2667 8.12545 20.2667ZM22.5183 21.9614L7.3942 15.885L7.01987 15.7341H3.4826V10.273H7.01987L7.3942 10.1221L22.5183 4.04572V21.9614Z"
    />
  </svg>
</template>

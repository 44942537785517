import type { Module } from 'vuex'
import type { IRootStore } from '../interfaces'
import { usePayments } from '@/views/residential/payments/hooks/usePayments'

const { getPayments, getHistoryPayments, getCurrentDebtSum } = usePayments()

const billing: Module<any, IRootStore> = {
  state: {
    payments: [],
    history_payments: [],
    allSum: {},
    currentDebt: 0,
    loading: true,
    billingError: '',
    error: null,
  },

  getters: {
    historyPayments: state => state.history_payments,
    payments: state => state.payments,
    allSum: state => state.allSum,
    currentDebt: state => state.currentDebt,
    loading: state => state.loading,
    error: state => state.error,
  },

  actions: {
    async getActionPayments({ commit }, userId, params = {}) {
      commit('setLoading', true)
      await getPayments(userId, params).then((data) => {
        commit('setPayments', data)
        commit('setLoading', false)
      })
    },

    async getActionHistoryPayments({ commit }, userId, params = {}) {
      await getHistoryPayments(userId, params).then((data) => {
        commit('setHistoryPayments', data)
        commit('setLoading', false)
      })
    },

    async getCurrentDebt({ commit }, contragentId) {
      commit('setLoading', true)
      await getCurrentDebtSum(contragentId).then((data) => {
        commit('setDebt', data)
        commit('setLoading', false)
      })
    },
  },

  mutations: {
    setPayments: (state, { data, totalAccrualSum, totalDebtSum }) => {
      state.payments = data || []
      state.loading = false
      state.allSum = {
        ...state.allSum,
        paymentSum: totalAccrualSum,
        debtSum: totalDebtSum,
      }
    },
    setHistoryPayments: (state, { data }) => {
      state.history_payments = data || []
      state.loading = false
    },
    setDebt: (state, data) => {
      state.currentDebt = data || 0
      state.loading = false
    },
    setLoading: (state, isLoading) => {
      state.loading = isLoading
    },
  },
}

export default billing

import { createApp } from 'vue'

// Libs
import { createHead } from '@unhead/vue'
import { VueRecaptchaPlugin } from 'vue-recaptcha'
import { initYandexMetrika } from 'yandex-metrika-vue3'
import { vMaska } from 'maska'

// Antd
import { Button, Input } from 'ant-design-vue'
import router from './router'
import store from './store'
import App from './App.vue'

// Styles
import './styles/main.sass'

// Layouts
import LayoutMain from '@/layouts/LayoutMain.vue'
import LayoutBread from '@/layouts/LayoutBread.vue'
import LayoutGuest from '@/layouts/LayoutGuest.vue'
import LayoutUnverified from '@/layouts/LayoutUnverified.vue'

import './registerServiceWorker'

const RECAPTCHA_SITE_KEY_V2 = '6LfuIrApAAAAAE5ot4qpJpO-dDbnCY5RgGgxQuOE'
const RECAPTCHA_SITE_KEY_V3 = '6LeRYrApAAAAAOLuUnqZY0cxPKtXxDpKfTsBnCvX'

const app = createApp(App)
const head = createHead()

app.directive('maska', vMaska)
app.component('LayoutMain', LayoutMain)
app.component('LayoutGuest', LayoutGuest)
app.component('LayoutBread', LayoutBread)
app.component('LayoutUnverified', LayoutUnverified)
app.use(head)
app.use(VueRecaptchaPlugin, {
  v2SiteKey: RECAPTCHA_SITE_KEY_V2,
  v3SiteKey: RECAPTCHA_SITE_KEY_V3,
})
  .use(initYandexMetrika, {
    id: '92963011',
    options: {
      defer: true,
      trackLinks: true,
      clickmap: true,
      accurateTrackBounce: true,
      webvisor: true,
      trackHash: true,
    },
    router,
    scriptSrc: 'https://mc.yandex.ru/metrika/tag.js',
    env: process.env.NODE_ENV,
  })
app.use(Button)
app.use(Input)
app.use(store)
app.use(router)
app.mount('#app')

import { computed } from 'vue'
import { useStore } from 'vuex'
import type { IStore } from '@/store/interfaces'

function mapState() {
  const store: IStore = useStore()
  return Object.fromEntries(
    Object.keys(store.state).map(key => [key, computed(() => store.state[key])]),
  )
}

function mapGetters() {
  const store: IStore = useStore()
  return Object.fromEntries(
    Object.keys(store.getters).map(getter => [getter, computed(() => store.getters[getter])]),
  )
}

function mapMutations() {
  const store: IStore = useStore()
  return Object.fromEntries(
    Object.keys(store._mutations).map(mutation => [mutation, (value?: any) => store.commit(mutation, value)]),
  )
}

function mapActions() {
  const store: IStore = useStore()
  return Object.fromEntries(
    Object.keys(store._actions).map(action => [action, (value?: any) => store.dispatch(action, value)]),
  )
}

export { mapState, mapGetters, mapMutations, mapActions }

<template>
  <div class="q-userbar">
    <div class="q-userbar__info">
      <div class="q-userbar__info-number">
        Л/C {{ numberPersonalAccount }}
      </div>
      <div class="q-userbar__info-name">
        {{ fullName }}
      </div>
    </div>
    <div class="q-userbar__icon">
      <router-link class="q-userbar__link" :to="`/resident/${getUser?.id}/appeals`">
        <ABadge
          :disabled="true"
          :count="countNotifications"
          :number-style="{ backgroundColor: '#1890FF' }"
        >
          <BellOutlined />
        </ABadge>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { BellOutlined } from '@ant-design/icons-vue'
import { Badge as ABadge } from 'ant-design-vue'
import { mapActions, mapGetters } from '@/utils/mapState'

const { getNotifications } = mapActions()

const { getUser, countNotifications } = mapGetters()

const fullName = computed<string>(() =>
  getUser?.value?.fullName ? getUser.value.fullName : 'Пользователь',
)

const numberPersonalAccount = computed<string>(() =>
  getUser?.value?.numberPersonalAccount ? getUser.value.numberPersonalAccount : 'Не определен',
)

onMounted(() => {
  getNotifications()
})
</script>

<style lang="sass" scoped>
@import './QUserbar'
</style>

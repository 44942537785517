import { DefaultAPIInstance } from '@/api'

const PaymentsService = {
  getPayments(userId, params) {
    return DefaultAPIInstance.get(`client/accruals/${userId}`, { params })
  },

  getFines(params) {
    return DefaultAPIInstance.get('client/fines', { params })
  },

  getHistoryPayments(userId, params) {
    return DefaultAPIInstance.get(`client/historyPayments/${userId}`, { params })
  },

  getCurrentDebt(contragentId) {
    const url = `client/currentDebtSum/${contragentId}`
    return DefaultAPIInstance.get(url)
  },
}

export default PaymentsService

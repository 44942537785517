import { useRoute, useRouter } from 'vue-router'
import { message } from 'ant-design-vue'
import type { TPaymentsHistoryPromise, TPaymentsPromise } from '../interfaces'
import PaymentsService from '@/services/residential/PaymentsService'

export function usePayments() {
  const route = useRoute()
  const router = useRouter()

  const setPage = (page: number) => {
    router.push({ query: { ...route.query, page } })
  }

  const getHistoryPayments: TPaymentsHistoryPromise = async (userId, params) => {
    try {
      const { data } = await PaymentsService.getHistoryPayments(userId, params)
      return data
    }
    catch (err: any) {
      return message.error(err.response.data?.details)
    }
  }

  const getPayments: TPaymentsPromise = async (userId, params) => {
    try {
      const { data } = await PaymentsService.getPayments(userId, params)
      return data
    }
    catch (err: any) {
      return message.error(err.response.data?.details)
    }
  }

  const getCurrentDebtSum = async (contragentId: string) => {
    let result = 0
    try {
      const { data } = await PaymentsService.getCurrentDebt(contragentId)
      result = data
    }
    catch (err: any) {
      message.error(err.response.data?.details)
    }
    return result
  }

  return {
    setPage,
    getPayments,
    getHistoryPayments,
    getCurrentDebtSum,
  }
}

<template>
  <div class="q-header-nav">
    <div class="q-header-nav__tabs" id="tabs" />
    <AButton
      v-if="isFilterBtn"
      class="q-header-nav__filter-btn"
      type="link"
      @click="store.commit('openSub')"
    >
      <FilterOutlined />
    </AButton>
  </div>
</template>

<script setup lang="ts">
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { FilterOutlined } from '@ant-design/icons-vue'
import { computed } from 'vue'
import type { IRouteMeta } from './interfaces'

const store = useStore()
const route = useRoute()

const isFilterBtn = computed<boolean>(() => {
  const { subHeader } = route.meta as IRouteMeta
  return !!subHeader?.left
})
</script>

<style lang="sass" scoped>
@import './QHeaderNav'
</style>

import { createStore } from 'vuex'
import type { IRootStore } from './interfaces'
import auth from '@/store/main/auth'
import header from '@/store/main/header'
import billing from '@/store/residential/billing'
import notification from '@/store/main/notification'

export default createStore<IRootStore>({
  modules: {
    auth,
    header,
    billing,
    notification,
  },
})

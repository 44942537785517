<!-- eslint-disable max-len -->
<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2857 0.138672H0.714286C0.319196 0.138672 0 0.457868 0 0.852958V15.1387C0 15.5338 0.319196 15.853 0.714286 15.853H19.2857C19.6808 15.853 20 15.5338 20 15.1387V0.852958C20 0.457868 19.6808 0.138672 19.2857 0.138672ZM18.3929 14.2458H1.60714V1.74581H18.3929V14.2458ZM12.1942 7.19224H14.9487C14.9777 7.19224 15 7.11189 15 7.01367V5.94224C15 5.84403 14.9777 5.76367 14.9487 5.76367H12.1942C12.1652 5.76367 12.1429 5.84403 12.1429 5.94224V7.01367C12.1429 7.11189 12.1652 7.19224 12.1942 7.19224ZM12.3013 10.4065H16.4464C16.5335 10.4065 16.6049 10.3262 16.6049 10.228V9.15653C16.6049 9.05832 16.5335 8.97796 16.4464 8.97796H12.3013C12.2143 8.97796 12.1429 9.05832 12.1429 9.15653V10.228C12.1429 10.3262 12.2143 10.4065 12.3013 10.4065ZM3.57143 11.5896H4.55134C4.64509 11.5896 4.72098 11.5159 4.72768 11.4222C4.8125 10.2949 5.75446 9.40207 6.89732 9.40207C8.04018 9.40207 8.98214 10.2949 9.06697 11.4222C9.07366 11.5159 9.14955 11.5896 9.2433 11.5896H10.2232C10.2474 11.5896 10.2714 11.5847 10.2937 11.5752C10.3159 11.5656 10.336 11.5517 10.3527 11.5341C10.3695 11.5166 10.3824 11.4959 10.3908 11.4731C10.3993 11.4504 10.403 11.4263 10.4018 11.4021C10.3393 10.2123 9.6875 9.17662 8.73661 8.58733C9.15594 8.12638 9.38765 7.52522 9.38616 6.90207C9.38616 5.52037 8.27232 4.40207 6.89955 4.40207C5.52679 4.40207 4.41295 5.52037 4.41295 6.90207C4.41295 7.55162 4.65848 8.1409 5.0625 8.58733C4.57841 8.88731 4.17412 9.3 3.88415 9.79015C3.59418 10.2803 3.42715 10.8333 3.39732 11.4021C3.38839 11.5047 3.46875 11.5896 3.57143 11.5896ZM6.89732 5.74135C7.53348 5.74135 8.05134 6.26144 8.05134 6.90207C8.05134 7.54269 7.53348 8.06278 6.89732 8.06278C6.26116 8.06278 5.7433 7.54269 5.7433 6.90207C5.7433 6.26144 6.26116 5.74135 6.89732 5.74135Z"
    />
  </svg>
</template>

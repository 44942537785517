<template>
  <header class="q-header-unverified">
    <div class="q-header-unverified__top container">
      <div class="q-header-unverified__logo">
        <Icon>
          <template #component>
            <component :is="QIconLogo" />
          </template>
        </Icon>
      </div>
      <h2 class="q-header-unverified__title">
        {{ route.query.name ? route?.query.name : route?.name }}
      </h2>

      <AButton @click="exit()">
        <template #icon>
          <LogoutOutlined />
        </template>
        <span class="q-header-unverified__exit">Выйти</span>
      </AButton>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

import Icon, { LogoutOutlined } from '@ant-design/icons-vue'
import useLogout from '@/сomposables/logout'
import QIconLogo from '@/icons/QIconLogo.vue'

const route = useRoute()
const exit = useLogout()
</script>

<style lang="sass" scoped>
@import './QHeaderUnverified'
</style>
